import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@core/services/alert.service';
import { BehaviorSubject } from 'rxjs';
import {
  CognitoUser,
  FormInView,
  registerPayload,
  LoggedInUserRole,
} from '../session.model';
import { SessionService } from '../session.service';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { ConfigService } from '@core/services/config.service';
import { AppPathRoutes } from 'src/app/constants/app.constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  showPassword = false;
  inputEmail: string = '';
  errorMsg: string = '';
  iserror: boolean = false;
  forgotPasswordSubmitEmail: string = '';
  signUploading: boolean = false;
  role = LoggedInUserRole;
  private _errorMessage$ = new BehaviorSubject('');
  private _formInView$ = new BehaviorSubject<FormInView>('LOGIN');
  private _user: CognitoUser;
  errorMessage$ = this._errorMessage$.asObservable();
  formInView$ = this._formInView$.asObservable();

  constructor(
    private session: SessionService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private cdn: ChangeDetectorRef,
    private appConfig: ConfigService
  ) {
    this.session.setLoading(false);
  }

  handleRoutePath(path: string) {
    switch (path) {
      case 'login':
        return this.showLoginForm();
      case 'signupmode':
        return this.showSignupModeForm();
      case 'signup':
        return this.showSignupForm();
      case 'change-password':
        return this.showChangePasswordForm();
      default:
        return this.showLoginForm();
    }
  }

  get redirectHome() {
    return this.appConfig.readConfig().homeUrl;
  }

  get hasGoogleLoginQueryParams() {
    return this.route.snapshot.queryParams?.provider === 'google' || false;
  }

  ngOnInit() {
    if (this.hasGoogleLoginQueryParams) {
      this.session.setLoading(true);
      this.googleSocialSignIn();
    }

    let urlPath = this.route.snapshot.url[0].path;
    this.inputEmail = this.route.snapshot.queryParams.email;
    this.handleRoutePath(urlPath);
  }

  googleSocialSignIn() {
    try {
      this.session.setLoading(true);
      this.session.googleSocialSignIn();
    } catch (error) {
      console.log(error);
      this.alertService.showAlert(
        'Unable to login using Google at this moment',
        'error'
      );
    }
  }

  async handleLogin(data: { email: string; password: string }) {
    this._errorMessage$.next('');
    const { data: user, error } = await this.session.login(
      data.email,
      data.password
    );
    if (error) {
      this._errorMessage$.next(error.message);
    }
    if (!this.session.hasUserAttributes) {
      this.handleNoAttributesFound(user);
      return;
    }

    this.session.setCurrentUser(user);
    this.redirectLoggedInUser();
  }

  async handleChangePassword(data: { password: string }) {}

  async handleForgotPassword(data: { email: string }) {
    const { data: string, error } = await this.session.forgotPassword(
      data.email
    );
    if (error) {
      return this._errorMessage$.next(error.message);
    }
    this.alertService.showAlert(
      'An email with resetting password instructions has been sent successfully',
      'success',
      3000
    );
    this.forgotPasswordSubmitEmail = data.email;
    this.showForgotPasswordSubmitForm();
  }

  handleBypassForgotPassword() {
    this.showForgotPasswordSubmitForm();
  }
  async handleForgotPasswordSubmit(data: {
    email: string;
    code: string;
    password: string;
  }) {
    const { data: string, error } = await this.session.submitForgotPassword(
      data.email,
      data.code,
      data.password
    );
    if (error) {
      setTimeout(() => {
        this._errorMessage$.next('');
      }, 5000);
      return this._errorMessage$.next(error.message);
    }
    this.alertService.showAlert(
      'Awesome! You password has been updated',
      'success',
      3000
    );
    this.showLoginForm();
  }
  async handleSetNewPassword(data: { password: string }) {
    const { data: string, error } = await this.session.setNewPassword(
      this._user,
      data.password
    );
    if (error) {
      return this._errorMessage$.next(error.message);
    }
    this.alertService.showAlert(
      'Password updated successfully.',
      'success',
      3000
    );
    this.showLoginForm();
  }

  onClickBack() {
    this.session.logoutQuietly();
    window.location.href = this.redirectHome;
  }

  gotologin() {
    this.showLoginForm();
  }

  handleNoAttributesFound(user: CognitoUser) {
    if (!user.challengeName) return;
    this.handleChallenge(user);
  }

  // handleSignup(data: {
  //   email: string;
  //   firstname: string;
  //   lastname: string;
  //   schoolname: string;
  //   isSocialSignup: boolean;
  //   isStudent?: boolean;
  //   classCode?: string;
  // }) {
  //   if (!data.isSocialSignup) {
  //     let payload: registerPayload;
  //     payload = {
  //       email: data.email,
  //       name: data.firstname,
  //       familyName: data.lastname,
  //       roleId: data.isStudent ? this.role.STUDENT : this.role.TEACHER,
  //     };
  //     this.signUploading = true;
  //     let additionalPayload = {};

  //     let refcode = localStorage.getItem('refCode');

  //     if (refcode && refcode !== 'undefined') {
  //       additionalPayload = { refcode: refcode };
  //     }
  //     if (data.isStudent) {
  //       additionalPayload = {
  //         ...additionalPayload,
  //         classCode: data.classCode,
  //       };
  //     }

  //     this.session.createNewUser(payload, additionalPayload).subscribe(
  //       (user) => {
  //         this.signUploading = false;
  //         this.alertService.showAlert(
  //           'Success! Please check your email for login info.',
  //           'success',
  //           5000
  //         );
  //         localStorage.removeItem('refCode');
  //         this.gotologin();
  //       },
  //       (error) => {
  //         this.signUploading = false;
  //         this.iserror = true;
  //         this.errorMsg = error;
  //         this.cdn.detectChanges();
  //       }
  //     );
  //   } else {
  //     this.signUploading = true;
  //     let cognitoUsername = this.session.accessTokenUsername;

  //     let payload = {
  //       firstName: data.firstname,
  //       lastName: data.lastname,
  //     };
  //     let additionalPayload = {};

  //     let refcode = localStorage.getItem('refCode');

  //     if (refcode && refcode !== 'undefined') {
  //       additionalPayload = { refcode: refcode };
  //     }
  //     if (data.isStudent) {
  //       additionalPayload = { ...additionalPayload, classCode: data.classCode };
  //     }

  //     this.session
  //       .registerNewUser(cognitoUsername, payload, additionalPayload)
  //       .subscribe(
  //         (user) => {
  //           this.signUploading = false;
  //           this.alertService.showAlert(
  //             'Success! You can now Login to get started.',
  //             'success',
  //             5000
  //           );
  //           localStorage.removeItem('refCode');
  //           this.session.logoutQuietly();
  //           this.gotologin();
  //         },
  //         (error) => {
  //           this.signUploading = false;
  //           this.session.logout();
  //           this.iserror = true;
  //           this.errorMsg = error;
  //           this.cdn.detectChanges();
  //         }
  //       );
  //   }
  // }

  handleChallenge(user: CognitoUser) {
    switch (user.challengeName) {
      case 'NEW_PASSWORD_REQUIRED':
        this._user = user;
        return this.showSetNewPasswordForm();
      default:
        return;
    }
  }

  showChangePasswordForm() {
    this._formInView$.next('CHANGE_PASSWORD');
  }
  showSetNewPasswordForm() {
    this._formInView$.next('SET_NEW_PASSWORD');
  }

  showLoginForm() {
    this._formInView$.next('LOGIN');
  }

  showSignupForm() {
    this._formInView$.next('SIGNUP');
  }

  showSignupModeForm() {
    this._formInView$.next('SIGNUP_MODE');
  }

  showForgotPasswordForm() {
    this._formInView$.next('FORGOT_PASSWORD');
  }

  showForgotPasswordSubmitForm() {
    this._formInView$.next('FORGOT_PASSWORD_SUBMIT');
  }

  redirectLoggedInUser() {
    if (!this.session.redirectUrl) {
      this.navigateToDashboard();
    } else {
      this.router.navigateByUrl(this.session.redirectUrl);
    }
  }

  navigateToDashboard() {
    switch (this.session.userRole) {
      case this.role.SUPER_ADMIN:
        this.router.navigateByUrl(AppPathRoutes.ROUTE_ORGANISATION);
        break;
      case this.role.ADMIN:
        this.router.navigateByUrl('/admin-dashboard');
        break;
      default:
        this.router.navigateByUrl('/dashboard');
        break;
    }
  }

  navigateToLanding() {
    this.router.navigateByUrl('/');
  }

  signInUsingGoogle() {
    try {
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
    } catch (error) {
      console.log(error, 'error');
    }
  }
}
